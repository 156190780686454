<template>
  <div>
    <div class="auto-height">
      <div class="card measure-side-header">
        <div class="card-body" style="padding-left:12px;">
          <h5 class="mb-0" v-if="assessment.type == 'control_assessment'">
            {{ $t('tasks.assessment.control_objectives_title') }}
          </h5>
          <div class="mb-0 row h5" v-if="assessment.type == 'risk_assessment'">
            <div v-if="assessment.scope === 'activity'" class="col-md-10">{{ $t('projects.tab.activity_scope') }}</div>
            <div v-if="assessment.scope === 'system'" class="col-md-10">{{ $t('projects.tab.system_scope') }}</div>
            <div v-if="assessment.scope === 'process'" class="col-md-10">{{ $t('projects.tab.process_scope') }}</div>
            <div v-if="assessment.scope === 'third_party'" class="col-md-10">{{ $t('projects.tab.third_party_scope') }}</div>

            <div class="col-md-2">
              <!-- Process scope tab -->
              <ProcessProjectTable @reloadContent="loadItems()" v-if="assessment.scope === 'process'" :object="assessment"></ProcessProjectTable>
              <!-- Activity scope tab -->
              <ActivitiesProjectTable @reloadContent="loadItems()" v-if="assessment.scope === 'activity'" :object="assessment"></ActivitiesProjectTable>
              <!-- System scope tab -->
              <SystemProjectTable @reloadContent="loadItems()" v-if="assessment.scope === 'system'" :object="assessment"></SystemProjectTable>
              <!-- Third party scope tab -->
              <ThirdPartiesProjectTable @reloadContent="loadItems()" v-if="assessment.scope === 'third_party'" :object="assessment"></ThirdPartiesProjectTable>
            </div>
          </div>
        </div>
      </div>

      <div v-for="(object, index) in tableItems"
           :key="'mc_' + index"
           class="filter-tree-item item-block cursor-pointer"
           :class="{'filter-tree-selected': selectedFilter.id == object.id}"
           @click="updateSelected(object)">
        <span class="cursor-pointer" :title="object.name">
          <font-awesome-icon icon="toolbox"/>
          {{ object.name }}
        </span>
        <button class="btn btn-link float-right"
                qid="delete-activity-modal"
                @click="showSConfirmDeleteModal(object)"
                v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], assessment) && assessment.status != 'closed' && assessment.status != 'completed' && assessment.type == 'risk_assessment'"
        >
          <font-awesome-icon icon="trash-alt" />
        </button>
      </div>

      <div v-if="!loading && !tableItems.length">
        <div class="card measure-side-header">
          <div class="card-body" style="padding-left:12px;">
            <h5 class="mb-0">
              {{ $t('system.no_results') }}
            </h5>
          </div>
        </div>
      </div>

      <div class="mt-5" v-if="loading">
        <div class="swing-container">
          <div class="swing">
            <div class="swing-l"></div>
            <div></div>
            <div></div>
            <div></div>
            <div class="swing-r"></div>
          </div>
        </div>
      </div>

    </div>

    <!-- Add system modal -->
    <b-modal ref="edit-system"
             hide-footer
             @hide="hideEditSModal"
             size="xl"
    >
      <div class="d-block text-center">
        <h4 qid="update-tp-title">
          {{ $t('projects.process.create.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="addItemsFormSubmit" qid="create-p-form">
        <div>
          <div class="row">
            <div class="col">
              <b-form-group>
                <label qid="edit-lb-category-label">
                  {{ $t('projects.process.create.add_label') }}
                </label>
                <div>
                  <b-form-input
                          type="text"
                          v-model="keywordValue"
                          @click="showSelectionTable = true"
                          qid="search-do-input"
                          v-on:input="debounceInput"
                          :placeholder="$t('projects.process.create.search_placeholder')"
                  ></b-form-input>
                </div>
              </b-form-group>
              <div>
                <label class="float-right cursor-pointer ml-3 pt-2"
                       @click="unselectAll"
                       qid="system-unselect-button"
                >
                  {{ $t('system.unselect_all') }}
                </label>
                <label class="float-right cursor-pointer pt-2"
                       @click="selectAll"
                       qid="system-select-button"
                >
                  {{ $t('system.select_all') }}
                </label>
              </div>
              <br>
              <div class="form-control scrollable-height-400"
                   qid="tp-source-data-objects"
              >
                <div>

                  <div class="table-item-div cursor-pointer"
                       v-for="(item, index) in DOItems"
                       :key="index"
                       @click="selectDataObject(index, item)"
                       :class="{'table-item-div-selected': item.selected}"
                  >
                    <div class="text-bold">
                      {{ item.name }}
                    </div>
                    <div>
                      <span class="mr-3">
                        <span v-if="item.tags_translated && item.tags_translated.length" qid="activity-tags-list">
                          <span v-for="(tag, index) in getReducedTags(item.tags_translated)"
                                :key="index"
                                :title="tag.name_translated"
                                qid="p-tags-list"
                          >
                            <span class="badge" :title="tag.name_translated">
                              <font-awesome-icon icon="tag"/>
                              {{ tag.name_translated | truncate(35) }}
                            </span>
                          </span>
                          <span v-if="item.tags_translated.length > 1"
                                class="badge cursor-pointer"
                                @click="openTagsModal(item, $event)"
                          >
                            <font-awesome-icon icon="tags"/>
                            + {{ item.tags_translated.length - 1 }}
                          </span>
                        </span>
                      </span>
                      <span class="mr-3" v-if="item.process_group">
                        <span v-if="item.process_group"
                              class="badge"
                        >
                          <font-awesome-icon icon="project-diagram"/>
                          {{ item.process_group.name }}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="text-center table-item-div" v-if="!sLoading && !DOItems.length">
                    <h5 class="mt-3">
                      {{ $t('system.no_results') }}
                    </h5>
                  </div>
                </div>
              </div>
              <div class="text-muted mt-2 mb-3">
                {{ $t('projects.create.selected_items_tip') }}
              </div>
            </div>
            <div class="col-1">
              <b-button class="mt-300 btn-block"
                        variant="success"
                        @click="addData"
                        size="sm"
                        qid="system-select-button"
              >
                {{ $t('system.add') }} >
              </b-button>
            </div>
            <div class="col">
              <b-form-group>
                <label qid="edit-s-data-label">
                  {{ $t('projects.process.create.select_item_label') }}
                </label>
                <div>
                  <b-form-input
                          type="text"
                          v-model="keywordFilterValue"
                          qid="search-do-input"
                          v-on:input="filterSelectedData"
                          :placeholder="$t('projects.process.create.filter_placeholder')"
                  ></b-form-input>
                </div>
              </b-form-group>
              <br>
              <div class="form-control scrollable-height-400 mt-3" qid="tp-source-data-objects">
                <div>

                  <div class="table-item-div cursor-pointer"
                       v-for="(itemDO, index) in allDataObjectsFiltered"
                       :key="index"
                       :class="{'table-item-div-selected': itemDO.related}"
                  >
                    <span class="pt-2 pb-2 pl-2 pr-2 float-right">
                      <font-awesome-icon
                              icon="times"
                              size="sm"
                              @click="removeDataItem(itemDO)"
                              qid="tp-source-data-object-remove"
                              v-if="itemDO.new_item"
                      />
                    </span>
                    <div class="text-bold">
                      {{ itemDO.name }}
                    </div>
                    <div>
                      <span class="mr-3">
                        <span v-if="itemDO.tags_translated && itemDO.tags_translated.length" qid="activity-tags-list">
                          <span v-for="(tag, index) in getReducedTags(itemDO.tags_translated)"
                                :key="index"
                                :title="tag.name_translated"
                                qid="p-tags-list"
                          >
                            <span class="badge" :title="tag.name_translated">
                              <font-awesome-icon icon="tag"/>
                              {{ tag.name_translated | truncate(35) }}
                            </span>
                          </span>
                          <span v-if="itemDO.tags_translated.length > 1"
                                class="badge cursor-pointer"
                                @click="openTagsModal(itemDO, $event)"
                          >
                            <font-awesome-icon icon="tags"/>
                            + {{ itemDO.tags_translated.length - 1 }}
                          </span>
                        </span>
                      </span>
                      <span class="mr-3" v-if="itemDO.process_group">
                        <span v-if="itemDO.process_group"
                              class="badge"
                        >
                          <font-awesome-icon icon="project-diagram"/>
                          {{ itemDO.process_group.name }}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div v-for="(itemDO, index) in sTableItemsFull"
                       :key="index"
                  >
                    <div class="table-item-div cursor-pointer"
                         :class="{'table-item-div-selected': itemDO.related}"
                         v-if="itemDO.model"
                    >
                      <div class="text-bold">
                        {{ itemDO.model.name }}
                      </div>
                      <div>
                        <span class="mr-3">
                          <span v-if="itemDO.model.tags_translated && itemDO.model.tags_translated.length" qid="activity-tags-list">
                            <span v-for="(tag, index) in getReducedTags(itemDO.model.tags_translated)"
                                  :key="index"
                                  :title="tag.name_translated"
                                  qid="p-tags-list"
                            >
                              <span class="badge" :title="tag.name_translated">
                                <font-awesome-icon icon="tag"/>
                                {{ tag.name_translated | truncate(35) }}
                              </span>
                            </span>
                            <span v-if="itemDO.model.tags_translated.length > 1"
                                  class="badge cursor-pointer"
                                  @click="openTagsModal(itemDO.model, $event)"
                            >
                              <font-awesome-icon icon="tags"/>
                              + {{ itemDO.model.tags_translated.length - 1 }}
                            </span>
                          </span>
                        </span>
                        <span class="mr-3" v-if="itemDO.model.process_group">
                          <span v-if="itemDO.model.process_group"
                                class="badge"
                          >
                            <font-awesome-icon icon="project-diagram"/>
                            {{ itemDO.model.process_group.name }}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="text-center table-item-div" v-if="!sTableItemsFull.length && !allDataObjectsFiltered.length">
                    <h5 class="mt-3">
                      {{ $t('system.no_results') }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideEditSModal"
                    qid="create-p-cancel-button"
          >
            {{ $t('systems.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    :disabled="buttonDisabled"
                    variant="success"
                    qid="create-p-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('systems.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>


    <!-- Delete system modal template -->
    <b-modal ref="delete-ac" hide-footer>
      <div class="d-block text-center">
        <h4 qid="delete-ac-title">
          {{ $t('activities.system.delete.title') }}
        </h4>
      </div>
      <h5 class="text-center">
        {{ $t('activities.system.delete.description') }}
        <span v-if="itemToDelete">{{ itemToDelete.name }}</span>
      </h5>
      <h6 class="text-center" v-if="showListOfTasks">
        {{ $t('projects.tab.tasks') }}
      </h6>
      <table class="table table-responsive-md" v-if="showListOfTasks && itemToDelete">
        <thead>
        <tr>
          <th>
            {{ $t('projects.table.name') }}
          </th>
          <th>
            {{ $t('system.status') }}
          </th>
        </tr>
        </thead>
        <tbody v-if="open_tasks && open_tasks.length">
        <tr v-for="(object, index) in open_tasks" :key="index">
          <td>
            <p>
                <span v-if="object.code == 'business_risk_assessment' || object.code == 'privacy_risk_assessment' || object.code == 'system_impact_assessment'">
                  {{ $t('maintenance.assessments.system_impact_assessment_label') }}
                </span>
              <span v-else>{{ object.title}}</span><br style="height:0px;"/>
              <span class="text-muted" v-if="object.code != 'risk_assessment'">
                  {{ object.model.name }}
                </span>
            </p>
          </td>
          <td>
              <span v-if="object.status === 'completed'" >
                  <font-awesome-icon icon="check-circle" class="icon-success"/>
              </span>
            <span v-if="object.status === 'pending'" class="pr-2">
                <font-awesome-icon
                        icon="exclamation-triangle"
                        class="risk-6"
                />
              </span>
            {{ object.status | capitalize }}
          </td>
        </tr>
        </tbody>

        <tbody v-if="open_tasks && !open_tasks.length" >
        <tr qid="no-results-row" class="text-center">
          <td colspan="10">
            <h5 class="mt-3">
              {{ $t('system.no_results') }}
            </h5>
          </td>
        </tr>
        </tbody>
      </table>
      <h5 class="text-center">
        {{ $t('activities.system.delete.are_you_sure') }}
      </h5>
      <div class="text-center">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="hideSConfirmDeleteModal"
                  qid="delete-ac-cancel-button"
        >
          {{ $t('activities.system.delete.cancel') }}
        </b-button>
        <b-button @click="confirmDeleteS(itemToDelete)"
                  class="mt-3 ml-3"
                  variant="danger"
                  qid="delete-ac-submit-button"
        >
          {{ $t('activities.system.delete.submit') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import piincHttp from '@/util/configureAxios.js';
import ActivitiesProjectTable from '@/views/Projects/Scope/ActivitiesProjectTable.vue';
import ProcessProjectTable from '@/views/Projects/Scope/ProcessProjectTable.vue';
import SystemProjectTable from '@/views/Projects/Scope/SystemProjectTable.vue';
import ThirdPartiesProjectTable from '@/views/Projects/Scope/ThirdPartiesProjectTable.vue';

export default {
  name: 'MeasureSideFilter',
  data: function() {
    return {
      loading: true,
      selectedFilter: {},
      tableItems: [],
      // Systems
      sTableItems: [],
      sTableItemsFull: [],
      selectedS: [],
      viewSModal: false,
      selectedSSort: '',
      selectedSDirection: '',
      sItems: [],
      sLoading: true,
      selectedItem: {},
      error: '',
      // Data objects
      allDataObjects: [],
      allDataObjectsFiltered: [],
      dataObjects: [],
      systemDataObjects: [],
      newDataObjects: [],
      DOItems: [],
      selectedDataObjects: [],
      availableDataObjects: [],
      keywordValue: '',
      buttonDisabled: false,
      keywordFilterValue: '',
      itemToDelete: {},
      showListOfTasks: false,
    }
  },
  props: {
    selectedCategory: {},
    assessment: {},
    updateList: {},
    loadingTasks: Boolean,
  },
  watch: {
    getClient: function () {
      this.loadItems();
    },
    selectedFilter: function () {

    },
    updateList: function (newValue, oldValue) {
      if (newValue) {
        this.loadItems();
      }
    },
    // Watcher for selected category - when selected item is changed from external component
    selectedCategory: function (newValue, oldValue) {
      if (newValue) {
        this.updateSelectedFromParent(newValue);
      // } else {
      //   this.resetSelectedWithReload();
      }
    },
    getselectedFilter: function (newValue, oldValue) {
      this.$emit('updateFilter', newValue);
    },

  },
  computed: {
    getClient() {
      return this.$store.state.client;
    },
    getselectedFilter() {
      return this.selectedFilter;
    }
  },
  components: {
    ActivitiesProjectTable,
    ProcessProjectTable,
    SystemProjectTable,
    ThirdPartiesProjectTable
  },
  methods: {
    debounceInput: _.debounce(function() {
      this.loadDO(this.keywordValue)
    }, 400),
    showEditSModal(object) {
      let self = this;
      let inArray = false;

      this.loadDO();

      this.allDataObjectsFiltered = _.cloneDeep(this.allDataObjects);
      this.$refs['edit-system'].show()
    },
    selectAll() {
      let newDataObjectsArray = []
      let self = this;
      _.forEach(this.DOItems, function (item) {
        item.selected = true;
        newDataObjectsArray.push(item)
      })
      this.DOItems = newDataObjectsArray;
    },
    unselectAll() {
      let newDataObjectsArray = []
      let self = this;
      _.forEach(this.DOItems, function (item) {
        item.selected = false;
        newDataObjectsArray.push(item)
      })
      this.DOItems = newDataObjectsArray;
    },
    showSConfirmDeleteModal(object) {
      this.itemToDelete = object
      this.showListOfTasks = false
      this.loadTasks(object);
      this.$refs['delete-ac'].show()
    },
    hideSConfirmDeleteModal() {
      this.itemToDelete = {}
      this.$refs['delete-ac'].hide()
    },
    loadTasks(model) {
      let self = this;
      // Call API for open tasks
      piincHttp.get('projects/' + this.assessment.slug + '/tasks', { params:
          {
            client: this.getClient.slug,
            model_type: model.who_am_i,
            model_id: model.id,
            per_page: 100
          }
      }).then(function(response) {
        self.open_tasks = response.data.items;

        piincHttp.get('projects/' + self.assessment.slug + '/tasks', { params:
            {
              client: self.getClient.slug,
              scope_type: model.who_am_i,
              scope_id: model.id,
              per_page: 100
            }
        }).then(function(response) {
          if (response.data.items.length) {
            self.open_tasks.push(...response.data.items)
          }
          if (self.open_tasks.length) {
            self.showListOfTasks = true;
          }
        }, function() {});
      }, function() {});
    },
    hideEditSModal() {
      this.selectedDataObjects = []
      this.keywordValue = ''
      this.keywordFilterValue = ''
      this.showSelectionTable = false
      this.allDataObjects = []
      this.systemRoleInput = ''
      this.error = ''
      this.$refs['edit-system'].hide()
    },
    confirmDeleteS(object) {

      let self = this;
      piincHttp.delete('projects/' + this.$route.params.slug + '/'+self.assessment.scope+'/' + object.id).then(function() {
        self.hideSConfirmDeleteModal();
        self.loadItems();
        self.$emit('updateSelected', self.tableItems[0]);
        self.buttonDisabled = false;
      }, function() {});
    },
    addItemsFormSubmit(evt) {
      this.buttonDisabled = true;
      // Keep the reference in a variable
      // to use it inside function called when promise returned
      let self = this;
      evt.preventDefault();

      self.sForm.items = []

      _.forEach(this.allDataObjects, function (item) {
        self.sForm.items.push({model: item.who_am_i, id: item.id})
      })

      if (self.sForm.items && self.sForm.items.length) {
        if (self.activity.type === 'control_assessment') {
          piincHttp.post('/projects/' + this.$route.params.slug + '/models', {
            items: this.sForm.items
          }).then(function() {
            self.$toastr('success', self.$t('projects.process.create.success'))
            self.hideEditSModal();
            self.loadProcesses();
            self.loadProcessesFull();
            self.buttonDisabled = false;
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
              if (error.data.errors.process_id) {
                self.error += ' ' + error.data.errors.process_id[0]
              }
            }
          })
        } else {
          piincHttp.post('/projects/' + this.$route.params.slug + '/models', {
            project_template: this.$route.params.slug,
            items: this.sForm.items
          }).then(function() {
            self.$toastr('success', self.$t('projects.process.create.success'))
            self.hideEditSModal();
            self.loadProcesses();
            self.loadProcessesFull();
            self.buttonDisabled = false;
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
              if (error.data.errors.process_id) {
                self.error += ' ' + error.data.errors.process_id[0]
              }
            }
          })
        }
      } else {
        this.error = this.$t('projects.process.create.required')
        self.buttonDisabled = false;
      }

    },
    addData() {
      let self = this;
      _.forEach(this.DOItems, function (item) {
        if (item.selected) {
          self.updateDOList(item)
        }
      })
      this.keywordValue = '';
      this.loadDO()
    },
    filterSelectedData() {
      let self = this;
      this.allDataObjectsFiltered = [];
      if (self.keywordFilterValue) {
        _.forEach(this.allDataObjects, function (item) {
          if (
            item.name.toLowerCase().indexOf(self.keywordFilterValue.toLowerCase()) !== -1 ||
            (item.category && item.category.toLowerCase().indexOf(self.keywordFilterValue.toLowerCase()) !== -1) ||
            (item.classification && item.classification.toLowerCase().indexOf(self.keywordFilterValue.toLowerCase()) !== -1)
          ) {
            self.allDataObjectsFiltered.push(item)
          }
        })
      } else {
        this.allDataObjectsFiltered = _.cloneDeep(this.allDataObjects);
      }
    },
    resetFields() {
      this.selectedRC = null
      this.selectedP = null
    },
    loadProcesses(page, perPage) {
      let self = this;

      if (this.getStorePerPage) {
        this.selectedPerPage = this.getStorePerPage;
      }

      self.sTableItems = []
      let pageNumber = page ? page : 1;
      let perPageNumber = perPage ? perPage : this.selectedPerPage;

      piincHttp.get('projects/' + this.$route.params.slug + '/models', { params:
          {
            page: pageNumber,
            per_page: perPageNumber,
            model_type: 'App\\Process\\Models\\Process',
            client: this.getClient.slug
          }
      }).then(function(response) {
        self.totalRows = response.data.total_count;
        self.sTableItems = response.data.items;
        self.sLoading = false;
      }, function() {});
    },
    loadProcessesFull() {
      let self = this;

      self.sTableItemsFull = []

      piincHttp.get('projects/' + this.$route.params.slug + '/models', { params:
          {
            per_page: 100,
            model_type: 'App\\Process\\Models\\Process',
            client: this.getClient.slug
          }
      }).then(function(response) {
        self.sTableItemsFull = response.data.items;
      }, function() {});
    },
    pageChanged(page) {
      // Load Third parties when page changed listener is triggered
      this.loadProcesses(page)
    },
    perPageChanged(perPage) {
      let self = this;
      // Load Third parties when page changed listener is triggered
      this.loadProcesses(1, perPage.value)
      self.selectedPerPage = perPage.value
      this.$store.commit('storePerPage', this.selectedPerPage);
    },
    perPageRemoved() {
      let self = this;
      // Load Third parties when page changed listener is triggered
      self.selectedPerPage = self.perPageOptions[0].value
      this.loadProcesses(1, self.selectedPerPage)

    },
    loadDO(query) {
      let self = this;
      self.DOItems = []
      piincHttp.get('processes', { params:
          {
            client: this.getClient.slug,
            per_page: 100,
            keyword: query ? query : undefined
          }
      }).then(function(response) {
        let results = response.data.items
        _.forEach(results, function (item) {
          if (_.findIndex(self.sTableItemsFull, function(o) {
            if (o.model) {
              return o.model.id == item.id
            }
          }) == -1 && _.findIndex(self.allDataObjects, function(o) { return o.id == item.id }) == -1) {
            self.DOItems.push(item)
          }
        })
      }, function() {});
    },
    selectDataObject(index, item) {
      // Toggle related/not related property
      item.selected = item.selected ? false : true;
      this.$set(this.DOItems, index, item)
    },
    updateDOList(selectedOption) {
      let self = this;
      let inArray = false;
      _.forEach(this.allDataObjects, function (item, index) {
        if (item.id == selectedOption.id) {
          // if item is already in array
          item.related = true;
          self.$set(self.allDataObjects, index, item)
          inArray = true;
          self.allDataObjectsFiltered = _.cloneDeep(self.allDataObjects);
        }
      })
      if (!inArray) {
        // If not in array, push it in array as related
        selectedOption.related = true;
        selectedOption.new_item = true;
        self.allDataObjects.push(selectedOption)
        self.allDataObjectsFiltered = _.cloneDeep(self.allDataObjects);
      }
    },
    loadItems() {
      let self = this;
      let currentItemIndex = 0;

      this.loading = true;

      if(self.assessment.type == 'control_assessment'){
        piincHttp.get('project-control-objectives', { params:
            {
              per_page: 100,
              project: self.assessment.id
            }
        }).then(function(response) {
          self.tableItems = [];

          _.forEach(response.data.items, function (item, index) {
            if (item.task_count > 0) {
              self.tableItems.push(item);

              if (self.selectedCategory && self.selectedCategory.id && item.id == self.selectedCategory.id) {
                currentItemIndex = index;
              }
            }
          })

          // Select either the first item from the list or the previously selected item automatically
          if (self.tableItems.length) {
            if (!self.selectedFilter) {
              self.updateSelected(self.tableItems[currentItemIndex]);
            }else{
              if (!self.selectedCategory || !self.selectedCategory.id) {
                self.updateSelected(self.tableItems[0]);
              }
            }
          }

          self.loading = false;
        }, function() {});
      }else{
        let modelType = ''
        if(self.assessment.scope == 'process'){
          modelType = 'App\\Process\\Models\\Process';
        }else if(self.assessment.scope == 'system'){
          modelType = 'App\\System\\Models\\System';
        }else if(self.assessment.scope == 'activity'){
          modelType = 'App\\Activity\\Models\\Activity';
        }else if(self.assessment.scope == 'third_party'){
          modelType = 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty';
        }
        piincHttp.get('projects/' + this.$route.params.slug + '/models', { params:
            {
              per_page: 100,
              model_type: modelType,
              client: this.getClient.slug
            }
        }).then(function(response) {


          self.tableItems = [];

          _.forEach(response.data.items, function (item, index) {

              self.tableItems.push(item.model);

              if (self.selectedCategory && self.selectedCategory.id && item.model.id == self.selectedCategory.id) {
                currentItemIndex = index;
              }

          })

          // Select either the first item from the list or the previously selected item automatically
          if (self.tableItems.length) {
            if (!self.selectedFilter) {
              self.updateSelected(self.tableItems[currentItemIndex]);
            }else{
              if (!self.selectedCategory || !self.selectedCategory.id) {
                self.updateSelected(self.tableItems[0]);
              }
            }
          }

          self.loading = false;
        }, function() {});
      }

    },

    // Function for updating selected filter - when user clicks on item
    updateSelected(item) {
      if (item && item.id) {
        // If new item is different from the last one
        if (item.id != this.selectedFilter.id && !this.loadingTasks) {
          // Select new item and emit event that we updated selected item
          this.selectedFilter = item;
          this.$emit('updateSelected', item);
        }
      }
    },

    // Function for updating selected filter - from parent component
    updateSelectedFromParent(item) {
      let self = this;
      if (item && item.id) {
        // First reset previous filter
        this.selectedFilter = {};
        this.loadItems();
        self.selectedFilter = item;

      }
    },
    reloadItems() {
      let self = this;
      this.loadItems();
      this.$emit('updateSelected', self.tableItems[0]);
    },
    // resetSelected() {
    //   this.selectedFilter = {};
    //   this.selectedFilterName = '';
    //   this.$emit('updateSelected');
    // },
    // resetSelectedWithReload() {
    //   this.selectedFilter = {};
    //   this.selectedFilterName = '';
    //   this.loadItems();
    //   this.$emit('updateSelected');
    // }
  },
  created () {
    let self = this;
    if (this.getClient && this.getClient.slug) {
      this.loadItems();
    }
  }
}
</script>
